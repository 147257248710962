<template>
  <section>
    <div class="listHeader">
      <h1 class="pl-3 homeHeader">Users</h1>
      <div class="grid grid-cols-7 gap-5 content-end">
        <div class="col-span-2 pl-3 text-12 pl-3"><router-link tag="a" to="/users">CLOSE X</router-link></div>
      </div>
      <div class="gradient_spacer relative w-full m-0"></div>
    </div>
    <div class="relative user-info rounded p-4 grey grid grid-cols-12 gap-5 text-white">
      <div class="col-span-2 h-full">
        <p>
          <img src="@/assets/img/shared/avatar_bobstevens.png" alt="Avatar Image: Bob Stevens" />
        </p>
        <h1 class="text-20 pt-2 pl-2">Bob Stevens</h1>
        <button class="absolute bottom-4 left-4 edit-btn cursor-not-allowed px-10 text-center">EDIT</button>
      </div>
      <div class="col-span-3 border-left h-full p-2">
        <h4 class="text-xs font-extrabold m-0 text-ltgrey">EMAIL</h4>
        <p class="text-16 mb-10">name@email.com</p>
        <h4 class="text-xs font-extrabold m-0 text-ltgrey">PHONE</h4>
        <p class="text-16 mb-10">123-456-7890</p>
        <h4 class="text-xs font-extrabold m-0 text-ltgrey">ROLE</h4>
        <p class="text-16">Maintenance</p>
      </div>
      <div class="col-span-7 border-left h-full p-2">
        <h4 class="text-xs font-extrabold text-ltgrey">ASSIGNMENTS</h4>
        <p class="mb-2">Orchard Gardens - Building 1</p>
        <p class="mb-2">Orchard Gardens - Building 2</p>
        <p class="mb-2">Orchard Gardens - Building 3</p>
      </div>
    </div>
    <div class="task-list relative w-full rounded p-4 grey mt-5 text-white">
      <h4 class="pl-2 text-xs font-extrabold">TASKS</h4>
      <p class="hr pl-2 pt-1 pb-2 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      <p class="hr pl-2 pt-1 pb-2 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      <p class="hr pl-2 pt-1 pb-2 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      <p class="hr pl-2 pt-1 pb-2 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      <div class="mt-12"><router-link to="/assign-task" el="button" class="edit-btn cursor-not-allowed px-10 text-center">ASSIGN NEW TASK</router-link></div>
    </div>
  </section>
</template>

<script>
// import propertiesPageHeader from '@/components/propertiesPageHeader.vue';
// import userlisting from '@/components/usersListItem.vue';

export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scope>
@import "../styles/typovars";

.user-info {
  min-height: 360px;

  button {
    background-color: #5c5b5b;
  }
}
.edit-btn {
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}
</style>
